<template>
  <div class="column">
    <div
      class="header"
      :style="{
        backgroundColor: headerColor,
        color: titleColor,
        height: headerHeight,
        borderRadius: !isContentVisible() ? '10px' : '10px 10px 0 0',
        transition: '0.4s',
      }"
      @click="toggleAccordion"
    >
      <span>
        {{ title }}
      </span>
      <div v-if="isMobile" class="icon-button">
        <IconSax
          type="outline"
          name="arrow-down-1"
          :style="{
            rotate: isContentVisible() ? '180deg' : '0deg',
            transition: '0.4s',
          }"
        />
      </div>
    </div>
    <b-collapse class="content" :visible="isContentVisible()">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headerColor: {
      type: String,
      default: "#ccc",
    },
    titleColor: {
      type: String,
      default: "#000",
    },
    headerHeight: {
      type: String,
      default: "auto",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggleAccordion() {
      if (this.isMobile) {
        this.isOpen = !this.isOpen;
      }
    },
    isContentVisible() {
      return !(this.isOpen && this.isMobile);
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  border-radius: 10px;
  background-color: var(--neutral-gray-80);
  height: fit-content;
}

.header {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  border-radius: 10px 10px 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;

  font-family: Inter;
  font-weight: bold;
  font-size: 16px;
}

.content {
  padding: 0 0.5rem 0 0.5rem;
}

.icon-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  ::v-deep .svgCss {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  svg path {
    fill: var(--maincolor) !important;
  }
}

@media (max-width: 768px) {
  .header {
    justify-content: space-between;
  }
}
</style>
