var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"header",style:({
      backgroundColor: _vm.headerColor,
      color: _vm.titleColor,
      height: _vm.headerHeight,
      borderRadius: !_vm.isContentVisible() ? '10px' : '10px 10px 0 0',
      transition: '0.4s',
    }),on:{"click":_vm.toggleAccordion}},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isMobile)?_c('div',{staticClass:"icon-button"},[_c('IconSax',{style:({
          rotate: _vm.isContentVisible() ? '180deg' : '0deg',
          transition: '0.4s',
        }),attrs:{"type":"outline","name":"arrow-down-1"}})],1):_vm._e()]),_c('b-collapse',{staticClass:"content",attrs:{"visible":_vm.isContentVisible()}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }